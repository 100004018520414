
import * as ipxRuntime$Xu1M7WuNZ5 from '/home/runner/work/s02679-telenor-no/s02679-telenor-no/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "media.test.bluestonepim.com",
    "cdn.cs.1worldsync.com",
    "test.telenor.no",
    "media.bluestonepim.com",
    "telenor.no",
    "www.telenor.no",
    "staging.telenor.no",
    "sttxs02679binarystorage.blob.core.windows.net",
    "stpxs02679binarystorage.blob.core.windows.net"
  ],
  "alias": {
    "/binaries": "https://stpxs02679binarystorage.blob.core.windows.net/files",
    "/test-binaries": "https://sttxs02679binarystorage.blob.core.windows.net/files"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "jpeg",
    "jpg",
    "png",
    "gif",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$Xu1M7WuNZ5, defaults: {} }
}
        